<template>
  <v-row>
    <v-col cols="12" md="12" >
      <v-radio-group v-model="value.tipoRelatorio" row 
       dense
        :rules="[
                this.$validators.string.required,
          ]">
        <v-radio
          label="Analítico"
          value="AN"
          :checked=true
        ></v-radio>
        <v-radio
          label="Sintético"
          value="SI"
        ></v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="12" md="4">
      <search-box
          label="* Contratado"
          popup-label="Selecione um Contratado"
          :id.sync="value.idContratado"
          :descricao.sync="value.descricaoContratado"
          :hidden-clear="false"
          :value="contratado"
          :loader-fn="loaderContratado"
          item-key="id"
          item-text="descricao"
          >
        <template v-slot:empty>
          <p class="ma-2">
            Nenhum Contratado encontrado
          </p>
        </template>
      </search-box>
    </v-col>       
    <v-col cols="12" md="8">
      <search-box 
          ref="comboContrato"
          label="* Contrato"
          popup-label="Selecione um Contrato"
          :id.sync="value.idContrato"
          :descricaoCompleta.sync="value.descricaoCompleta"
          :hidden-clear="false"
          :value="contrato"
          :loader-fn="loaderContrato"
          item-key="id"
          item-text="descricaoCompleta"
          >
        <template v-slot:empty>
          <p class="ma-2">
            Nenhum Contrato encontrada
          </p>
        </template>
      </search-box>
    </v-col>
    <v-col cols="12" md="4">
      <lookup-dominio label="* Status"
                      :hidden-clear="false"
                      hint="Selecione o Status"
                      v-model="value.status"
                      :type="lookups.status"
                      />
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field label="Data Inicio"
                    dense
                    type="date"
                    v-model="value.dataInicioContrato"
                    :value="this.inicioMes"
                    />
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field label="Data Fim"
                    dense
                    type="date"
                    v-model="value.dataTerminoContrato"
                    :value="this.fimMes"
                    />
    </v-col>
  </v-row>
</template>

<script>

import {ItemDeDominio} from "../../../../api/itemsDominio";
import LookupDominio from "../../common/LookupDominio";
import OrderInput from "../../common/OrderInput";
import SearchBox from "../../common/SearchBox";
import moment from 'moment';

export default {
  name: "RelatorioContratoForm",
  components: {
    SearchBox,
    LookupDominio,
    OrderInput
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      lookups: {
        status: ItemDeDominio.STATUS_CONTRATO,
      },
    }
  },
  watch: {
    'value.idContratado': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$refs.comboContrato.resetValues();
          this.$refs.comboContrato.search();
        }
      }
    },
  },
  computed: {
    inicioMes: function () {
      if (typeof this.value.dataIni === "undefined"){
        this.value.dataIni = moment().startOf('month').format('YYYY-MM-DD');
      }
    },
    fimMes: function () {
      if (typeof this.value.dataFim === "undefined"){
        this.value.dataFim = moment().endOf('month').format('YYYY-MM-DD');
      }
    },
    contrato(){
      if (this.value.idContrato == null) return null;
      return {
        id: this.value.idContrato,
        descricaoCompleta: this.value.descricaoCompleta
      }      
    }, 
    contratado() {
      if (this.value.idContratado == null) return null;
      return {
        id: this.value.idContratado,
        descricao: this.value.descricaoContratado
      }
    },          
  },
  methods: {
    loaderContrato(page, search) {
      return findAllContrato(page, {
        nome: search,
        idContratado: this.value.idContratado
      })
    },  
    loaderContratado(page, search) {
      return findAllContratado(page, {
        nome: search
      })
    },    
  }
}
</script>

<style scoped lang="scss">
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}


</style>